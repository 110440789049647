import React from 'react';
import { Grid } from 'semantic-ui-react';
import './Footer.css';
import logo from '../images/charityMilesLogoHQ.png';

function Footer() {
  return (
      <Grid className={"footer"}>
          <Grid.Column computer={1} mobile={16}>
          </Grid.Column>
          <Grid.Column computer={6} mobile={16}>
              <div className={"logo-container"}>
                  <a href={"https://charitymiles.org/"}>
                      <img src={logo} alt="charity miles logo" width={165} height={52}/>
                  </a>
              </div>
          </Grid.Column>
          <Grid.Column computer={4} mobile={16}>
                <div className={"section"}>
                    <div className={"section-title"}>
                        EXPLORE
                    </div>
                    <div className={"section-item"}>
                        <a href={"https://charitymiles.org/sponsors/"}>Sponsor Us</a>
                    </div>
                    <div className={"section-item"}>
                        <a href={"https://charitymiles.org/charities/"}>Our Charities</a>
                    </div>
                    <div className={"section-item"}>
                        <a href={"https://charitymiles.org/members/"}>Members</a>
                    </div>
                    <div className={"section-item"}>
                        <a href={"https://charitymiles.org/press/"}>Press</a>
                    </div>
                </div>
          </Grid.Column>
          <Grid.Column computer={4} mobile={16}>
              <div className={"section"}>
                  <div className={"section-title"}>
                      INFO
                  </div>
                  <div className={"section-item"}>
                      <a href={"https://charitymiles.org/shipping-returns/"}>Shipping & Returns</a>
                  </div>
                  <div className={"section-item"}>
                      <a href={"https://charitymiles.org/contact/"}>Contact</a>
                  </div>
                  <div className={"section-item"}>
                      <a href={"https://charitymiles.org/faq/"}>FAQ</a>
                  </div>
              </div>
          </Grid.Column>
          <Grid.Column computer={1} mobile={16}>
          </Grid.Column>
      </Grid>
  );
}

export default Footer;

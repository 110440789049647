import React from 'react';
import './PastCampaigns.css';
import {Segment, Dimmer, Loader, Image} from 'semantic-ui-react'
import cmAPI from "../axiosInstance";
import Moment from "react-moment";

class PastCampaigns extends React.Component {
    state={
        loading: true,
        pledgeCampaigns: null
    };

    componentDidMount(){
        let param = this.props.pageParam;
        let isUnid = this.props.isUnid;
        let getPromise;
        if(isUnid){
            getPromise = cmAPI.get("/pledging/members/v2/"+param+"/campaigns/past");
        }else{
            let userID = decodeURI(param);
            getPromise = cmAPI.get("/pledging/members/campaigns/past",{
                params:{
                    userID: userID
                }
            })
        }
        getPromise.then((response)=> {
                this.setState({
                    pledgeCampaigns: response.data.data.pledgeCampaigns,
                    loading: false
                });
            })
            .catch((error)=> {
                console.log(error);
            });
    }

    redirectToPledgeCampaignPage = (pledgeUnid) =>{
        this.props.history.push('/pledges/'+pledgeUnid);
    };

    getContent = () =>{
        if(this.state.loading){
            return(
                <Segment className={"loading-section"}>
                    <Dimmer active>
                        <Loader>Loading</Loader>
                    </Dimmer>
                </Segment>
            );
        }else{
            return(
                <>
                    <div className={"title-section" +(this.props.only===true?" only":"")}>
                        Past Pledge Drives
                    </div>
                    {this.state.pledgeCampaigns.map((pledgeCampaign, index)=>{
                        return this.getCampaignRow(pledgeCampaign,index);
                    })}
                </>
            );
        }
    };

    getCampaignRow = (pledgeCampaign, index) =>{
        let css = ["pledge-campaign-row"];
        if(index===0){
            css.push("first");
        }
        return(
            <div key={"pledge-campaign-"+index} className={css.join(" ")} onClick={()=>this.redirectToPledgeCampaignPage(pledgeCampaign.unid)}>
                <div className={"logo-container"}>
                    <Image className="campaign-charity-logo" src={pledgeCampaign.charity.imageLink} style={{"backgroundColor":pledgeCampaign.charity.backgroundColor}} size='medium' circular/>
                </div>
                <div className={"details-container"}>
                    <div className={"details"}>
                        <div>
                            {new Intl.NumberFormat('en-US', {
                                style: 'currency',
                                currency: 'USD'
                            }).format(pledgeCampaign.currentMoney)} raised for {pledgeCampaign.charity.name}
                        </div>
                        <div>
                            <Moment utc={true} format={"MMMM D, YYYY"}>{pledgeCampaign.startDate}</Moment> - <Moment utc={true} format={"MMMM D, YYYY"}>{pledgeCampaign.endDate}</Moment>
                        </div>
                        <div>
                            {pledgeCampaign.currentMiles.toFixed(2)} Miles
                        </div>
                    </div>
                </div>
            </div>
        );
    };

    render() {
        if(this.state.pledgeCampaigns!=null){
            if(this.props.noActiveCampaign && this.state.pledgeCampaigns.length===0){
                return(
                    <div className={"past-campaigns"}>
                        <div className={"title-section"}>
                            You have not created any pledge campaigns. Try creating one!
                        </div>
                    </div>
                );
            }
            if(this.state.pledgeCampaigns.length>0){
                return (
                    <div className={"past-campaigns"}>
                        {this.getContent()}
                    </div>
                );
            }
        }
        return null;
    }
}

export default PastCampaigns;

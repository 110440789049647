import React from 'react';
import './GiveAway.css';
import {Grid, Image} from 'semantic-ui-react'
import giveawayImage from '../images/give-away.png';

function GiveAway(props) {
    return (
        <div className={"give-away"}>
            <div className={"give-away-title"}>
                Giveaway
            </div>
            <Grid stretched className={"content-grid"}>
                <Grid.Column computer={8} mobile={16} verticalAlign={"middle"}>
                    <div className={"message-aligner"}>
                        <div className={"message"}>
                            <b>GRAND PRIZE Winner Will Receive:</b>
                            <br/>
                            A luxurious 5-night stay at the The Westin Maui Resort & Spa, Ka'anapali in Hawaii and roundtrip airfare for two people.
                            <br/>
                            <br/>
                            <br/>
                            <br/>
                            Winner will be announced on <span className={"date"}>September 4, 2019</span>
                        </div>
                    </div>
                </Grid.Column>
                <Grid.Column computer={8} mobile={16}>
                    <Image src={giveawayImage}/>
                </Grid.Column>
            </Grid>
        </div>
    );
}

export default GiveAway;

import React from 'react';
import './ThankYou.css';
import Navbar from "../navigation/Navbar";
import Footer from "../navigation/Footer";

class ThankYou extends React.Component {
    componentDidMount() {
        window.scrollTo(0, 0);
    }

    setHttp = (link) =>{
        if (link.search(/^http[s]?\:\/\//) == -1) {
            link = 'http://' + link;
        }
        return link;
    };

    render() {
        return (
            <>
                <Navbar/>
                <div className="thank-you">
                    <div className={"message"}>
                        Thank you for your pledge!
                        <div className={"sub-message"}>
                            <div>
                                One moment while we redirect you to the charity’s website so you can make your donation.
                            </div>
                            <div className={"redirect"}>
                                If you are not redirected, please click <a href={this.setHttp(this.props.link)}>here</a> to make your so you can make your donation.
                            </div>
                        </div>
                    </div>
                </div>
                <Footer/>
            </>
        );
    }
}

export default ThankYou;

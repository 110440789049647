import React from 'react';
import {Accordion, Icon, Menu} from 'semantic-ui-react';
import './Navbar.css';
import logo from '../images/charityMilesLogo.png';


class Navbar extends React.Component{
    state={
        sidebarOpen: false
    };
    render(){
        return (
            <Menu inverted className={"navbar"}>
                <Menu.Item link>
                    <a href={"https://charitymiles.org/"}>
                        <img src={logo} alt="charity miles logo"/>
                    </a>
                </Menu.Item>
                <Menu.Item name='How It Works' link className={"desktop"}>
                    <a href={"https://charitymiles.org/how-it-works"}>How It Works</a>
                </Menu.Item>
                <Menu.Item name='Charities' link className={"desktop"}>
                    <a href={"https://charitymiles.org/charities/"}>Charities</a>
                </Menu.Item>
                <Menu.Item name='Blog' link className={"desktop"}>
                    <a href={"https://charitymiles.org/blog/"}>Blog</a>
                </Menu.Item>
                <Menu.Item name='Shop' link className={"desktop"}>
                    <a href={"https://shop.charitymiles.org/collections/frontpage"}>Shop</a>
                </Menu.Item>
                <Menu.Item name='sidebarIcon' link className={"mobile"} onClick={()=>this.setState({sidebarOpen:!this.state.sidebarOpen})}>
                    <Icon name={"sidebar"}/>
                </Menu.Item>
                <div className={"mobile-sidebar"}>
                    <Accordion>
                        <Accordion.Content className={this.state.sidebarOpen?"open":"closed"}>
                            <Menu inverted className={"navbar"} vertical>
                                <Menu.Item name='How It Works' link>
                                    <a href={"https://charitymiles.org/how-it-works"}>How It Works</a>
                                </Menu.Item>
                                <Menu.Item name='Charities' link>
                                    <a href={"https://charitymiles.org/charities/"}>Charities</a>
                                </Menu.Item>
                                <Menu.Item name='Blog' link>
                                    <a href={"https://charitymiles.org/blog/"}>Blog</a>
                                </Menu.Item>
                                <Menu.Item name='Shop' link>
                                    <a href={"https://shop.charitymiles.org/collections/frontpage"}>Shop</a>
                                </Menu.Item>
                            </Menu>
                        </Accordion.Content>
                    </Accordion>
                </div>
            </Menu>
        );
    }
}

export default Navbar;

import React from 'react';
import './HowItWorks.css';

function HowItWorks(props) {
    let css = ["how-it-works"];
    if(!(props.className===null || props.className===undefined)){
        css.push(props.className);
    }
    return (
        <div className={css.join(" ")}>
            <div className={"diagonal-box"}>
                <div className={"box-wrapper"}>
                    <div className={"title"}>
                        How it works:
                    </div>
                    <div className={"list-aligner"}>
                        <ul className={"list"}>
                            <li>You pledge how much you want to donate per mile.</li>
                            <li>The Charity Miles member will put in the miles and you’ll receive progress updates along the way.</li>
                            <li>When the fundraising period ends you’ll receive an email with how to donate to the charity.</li>
                        </ul>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default HowItWorks;

import React from 'react';
import './CampaignStats.css';
import {Grid } from 'semantic-ui-react'

function CampaignStats(props) {
  return (
    <div className={"campaign-stats"}>
        <Grid>
            <Grid.Column computer={8} mobile={16}>
              <div className={"stat-title"}>Miles Completed</div>
              <div className={"stat-number"}>{props.campaignData.currentMiles.toFixed(2)}</div>
                <div className={"subtitle"}>
                    combined pledges: <b>{new Intl.NumberFormat('en-US', {
                    style: 'currency',
                    currency: 'USD'
                }).format(props.combinedPledgeAmount)} per mile</b>
                </div>
            </Grid.Column>
            <Grid.Column computer={8} mobile={16}>
              <div className={"stat-title"}>Miles Goal</div>
              <div className={"stat-number"}>{props.campaignData.milesGoal}</div>
                <div className={"subtitle"}>
                    estimate of total donations: <b>{new Intl.NumberFormat('en-US', {
                    style: 'currency',
                    currency: 'USD'
                }).format(props.estimatedTotal)}</b>
                </div>
            </Grid.Column>
        </Grid>
    </div>
  );
}

export default CampaignStats;

import React from 'react';
import './CampaignDetails.css';
import { Image,Grid } from 'semantic-ui-react';
import Moment from 'react-moment';

function CampaignDetails(props) {
  return (
    <div className={"campaign-details"}>
        <div className="title">
            {props.campaignData.user.fName}'s Pledge Page
        </div>
        <Image className="pledge-cover" src={props.campaignData.photoPledgeCover}/>
        <div className={"relative"}>
            <div className="pledge-cover-overlay"/>
        </div>
        <div className={"relative"}>
            <Image className="charity-logo-wrapper" src="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAEAAAABCAQAAAC1HAwCAAAAC0lEQVR42mNgYAAAAAMAASsJTYQAAAAASUVORK5CYII=" size='medium' circular/>
            <Image className="charity-logo" src={props.campaignData.charity.imageLink} style={{"backgroundColor":props.campaignData.charity.backgroundColor}} size='medium' circular/>
        </div>
        <div className={"relative"}>
            <Grid className={"cover-message-aligner"} verticalAlign="bottom">
                <Grid.Column computer={5} mobile={1} stretched/>
                <Grid.Column computer={6} mobile={16} style={{height:"100%"}}>
                    <div className="message-content-wrapper">
                        <div>
                            <div className={"pledge-cover-message"}>
                                Pledge To Sponsor {props.campaignData.user.fName}’s Miles For {props.campaignData.charity.name}.
                            </div>
                            {props.campaignData.complete?null:<div className={"center-h"}><button className={"ui button pledge-button"} onClick={props.scrollToPledgeForm}>Pledge</button></div>}
                            <div className="pledge-closing-message">
                                Pledging ends <Moment utc={true} format={"MMMM D, YYYY"}>{props.campaignData.endDate}</Moment>
                            </div>
                        </div>
                    </div>
                </Grid.Column>
                <Grid.Column computer={5} mobile={1}/>
            </Grid>
        </div>
    </div>
  );
}

export default CampaignDetails;

import React from 'react';
import Navbar from "../navigation/Navbar";
import Footer from "../navigation/Footer";
import './Home.css';

class Home extends React.Component {
    render() {
        return(
            <div className={"home"}>
                <div className={"contents"}>
                    <Navbar/>
                    <h1>
                        Use path /members/:userID to view pledge campaigns
                    </h1>
                    <Footer/>
                </div>
            </div>
        );
    }
}

export default Home;

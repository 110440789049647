let local = {
    api:{
        endpoint: "http://localhost:5000"
    },
};

let dev = {
    api:{
        endpoint: "https://o86a2uajrd.execute-api.us-east-1.amazonaws.com/dev"
    },
};

let prod = {
    api:{
        endpoint: "https://o86a2uajrd.execute-api.us-east-1.amazonaws.com/prod"
    },
};

const config  = () =>{
    console.log(process.env.REACT_APP_ENV);
    return process.env.REACT_APP_ENV === "prod"?prod:process.env.REACT_APP_ENV === "dev"?dev:local;
};

export default config();

import React from 'react';
import 'semantic-ui-css/semantic.min.css'
import { BrowserRouter as Router, Route } from "react-router-dom";
import PledgeCampaign from "./PledgeCampaign";
import Home from "./Home";
import ThankYou from "./ThankYou";
import ConfirmPledge from "./ConfirmPledge";

class App extends React.Component {
    render() {
        return(
            <Router>
                <Route path="/" exact component={Home} />
                <Route path="/members/:userID" exact component={PledgeCampaign} />
                <Route path="/pages/:userUnid" exact component={PledgeCampaign} />
                <Route path="/thankyou" exact component={ThankYou} />
                <Route path="/pledges/:pledgeUnid/confirm" exact component={ConfirmPledge} />
                <Route path="/pledges/:pledgeUnid" exact component={PledgeCampaign}/>
            </Router>
        );
    }
}

export default App;

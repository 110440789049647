import React from 'react';
import './PledgeCampaign.css';
import 'semantic-ui-css/semantic.min.css'
import Navbar from "../navigation/Navbar";
import CampaignDetails from "../components/CampaignDetails";
import CampaignStats from "../components/CampaignStats";
import HowItWorks from "../components/HowItWorks";
import PledgeForm from "../components/PledgeForm";
import DonorList from "../components/DonorList";
import GiveAway from "../components/GiveAway";
import cmAPI from "../axiosInstance";
import { Dimmer, Loader} from 'semantic-ui-react'
import PastCampaigns from "../components/PastCampaigns";
import Footer from "../navigation/Footer";
import ThankYou from "./ThankYou";

class PledgeCampaign extends React.Component {

    state={
        pledgeCampaign:null,
        pledges: null,
        loading: true,
        userPage: true,
        noActivePledgeCampaigns: false,
        pageParam: "",
        isUnid: false,
        isThankYouPage: false
    };

    myRef = React.createRef();

    scrollToPledgeForm = () => window.scrollTo(0, this.myRef.current.offsetTop-200);

    componentDidMount(){
        let pledgeCampaignUnid = this.props.match.params.pledgeUnid;
        let userID = this.props.match.params.userID;
        let userUnid = this.props.match.params.userUnid;
        if(!(userUnid === null || userUnid ===undefined)){
            this.setState({pageParam:userUnid,isUnid:true});
            this.loadUserPledge(userUnid,true);
        } else if(!(userID === null || userID ===undefined)){
            this.setState({pageParam:userID,isUnid:false});
            this.loadUserPledge(userID,false);
        }else if(!(pledgeCampaignUnid === null || pledgeCampaignUnid === undefined)){
            this.setState({pageParam:pledgeCampaignUnid,isUnid:false});
            this.loadPledgeCampaign(pledgeCampaignUnid);
        }
    }

    loadUserPledge = (param,isUnid) =>{
        let getPromise;
        if(isUnid){
            getPromise = cmAPI.get("/pledging/members/v2/"+param+"/campaigns");
        }else{
            let userID = decodeURI(param);
            getPromise = cmAPI.get("/pledging/members/campaigns",{
                params:{
                    userID: userID
                }
            })
        }
        getPromise.then((response)=> {
            let pledgeCampaign = response.data.data.pledgeCampaign;
            if(pledgeCampaign!==null) {
                cmAPI.get("/pledging/campaigns/" + pledgeCampaign.unid + "/pledges")
                    .then((response) => {
                        this.setState({
                            loading: false,
                            pledges: response.data.data.pledges,
                            pledgeCampaign: pledgeCampaign,
                        });
                    })
                    .catch((error) => {
                        console.log(error);
                        this.props.history.push('/');
                    });
            }else{
                this.setState({
                    loading: false,
                });
            }
        })
        .catch((error)=> {
            if(error.request && error.request.response){
                let data = JSON.parse(error.request.response);
                if(data.error === "pledge campaign not found"){
                    this.setState({
                        loading: false,
                        noActivePledgeCampaigns: true
                    });
                }
            }else {
                console.log(error);
                this.props.history.push('/');
            }
        });
    };

    loadPledgeCampaign = (pledgeCampaignUnid) =>{
        cmAPI.get("/pledging/campaigns/"+pledgeCampaignUnid)
        .then((response)=> {
            let pledgeCampaign = response.data.data.pledgeCampaign;
            cmAPI.get("/pledging/campaigns/"+pledgeCampaign.unid+"/pledges")
                .then((response)=> {
                    this.setState({
                        loading: false,
                        pledges: response.data.data.pledges,
                        pledgeCampaign: pledgeCampaign,
                        userPage: false
                    });
                })
                .catch((error)=> {
                    console.log(error);
                    this.props.history.push('/');
                });
        })
        .catch((error)=> {
            if(error.request && error.request.response){
                let data = JSON.parse(error.request.response);
                if(data.error === "pledge campaign not found"){
                    this.setState({
                        loading: false,
                        noActivePledgeCampaigns: true
                    });
                }
            }else{
                console.log(error);
                this.props.history.push('/');
            }
        });
    };

    getCombinedPledgeAmount = () =>{
        let total = 0;
        for (let x = 0; x < this.state.pledges.length; x++) {
            total += this.state.pledges[x].moneyAmount;
        }
        return total;
    };

    setHttp = (link) =>{
        if (link.search(/^http[s]?\:\/\//) == -1) {
            link = 'http://' + link;
        }
        return link;
    };

    onPledgeSuccess = () =>{
        this.setState({isThankYouPage:true});
        let link = this.state.pledgeCampaign.donateLink.trim();
        if(link ===undefined || link===null || link.length<=0 || link === "none"){
            link = this.state.pledgeCampaign.charity.donationPage;
        }
        if(link !==undefined && link!==null && link.length>0 && link !== "none"){
            let redirect = this.setHttp(link);
            setTimeout(()=>{window.location.href = redirect;}, 3000);
        }
    };

    render() {
        if(this.state.loading){
            return(
                <Dimmer active>
                    <Loader content='Loading' />
                </Dimmer>
            );
        }
        if(this.state.isThankYouPage){
            return(
                <ThankYou link={this.state.pledgeCampaign.donateLink}/>
            );
        }
        if(this.state.noActivePledgeCampaigns){
            return(
                <div className="pledge-campaign">
                    <div className={"contents"}>
                        <Navbar/>
                        <HowItWorks className={"no-campaigns"}/>
                        {this.state.userPage?<PastCampaigns noActiveCampaign={true} history={this.props.history} pageParam={this.state.pageParam} isUnid={this.state.isUnid}/>:null}
                        <Footer/>
                    </div>
                </div>
            );
        }
        let combinedPledges = 0;
        let estimatedTotal = 0;
        if(this.state.pledgeCampaign!==null){
            combinedPledges = this.getCombinedPledgeAmount();
            estimatedTotal = this.state.pledgeCampaign.currentMoney;
        }
        return (
            <div className="pledge-campaign">
                <div className={"contents"}>
                    <Navbar/>
                    {this.state.pledgeCampaign!==null?<> <CampaignDetails campaignData={this.state.pledgeCampaign} scrollToPledgeForm={this.scrollToPledgeForm}/>
                        <CampaignStats campaignData={this.state.pledgeCampaign} combinedPledgeAmount={combinedPledges} estimatedTotal={estimatedTotal}/>
                        <HowItWorks/>
                        <div ref={this.myRef}>
                            <PledgeForm campaignData={this.state.pledgeCampaign} onSuccess={this.onPledgeSuccess}/>
                        </div>
                        <DonorList campaignData={this.state.pledgeCampaign} pledges={this.state.pledges} scrollToPledgeForm={this.scrollToPledgeForm} combinedPledgeAmount={combinedPledges} estimatedTotal={estimatedTotal}/></>:null}
                    {this.state.userPage?<PastCampaigns only={this.state.pledgeCampaign==null} history={this.props.history} pageParam={this.state.pageParam} isUnid={this.state.isUnid}/>:null}
                    <Footer/>
                </div>
            </div>
        );
    }
}

export default PledgeCampaign;

import React from 'react';
import './PledgeForm.css';
import {Form} from 'formsy-semantic-ui-react';

import {Label} from 'semantic-ui-react';
import cmAPI from "../axiosInstance";

import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

class PledgeForm extends React.Component {
    state={
        amountPerMile: null,
        fullName: null,
        email: null,
        doNotShow: null,
        receiveEmails: true,
        validForm: false,
        submitting: false
    };

    getEstimate(){
        if(isNaN(Number(this.state.amountPerMile))){
            return 0;
        }
        let leftMiles = this.props.campaignData.milesGoal - this.props.campaignData.currentMiles;
        if(leftMiles<=0){
            return -1;
        }
        if(this.state.amountPerMile!==null && this.state.amountPerMile!==undefined){
            if(leftMiles>0){
                return (Number(this.state.amountPerMile)/100) * leftMiles;
            }else{
                return -1;
            }
        }
        return 0;
    }

    onSubmit = () =>{
        this.setState({submitting:true});
        let body = {
            moneyAmount: Number(this.state.amountPerMile)/100,
            name: this.state.fullName,
            email: this.state.email,
            hideOnDonorList: this.state.doNotShow,
            emailProgressUpdates: this.state.receiveEmails
        };
        let offset = new Date().getTimezoneOffset()/-60;
        cmAPI.put("/pledging/campaigns/"+this.props.campaignData.unid+"/pledges?offset="+offset,body)
        .then((response)=> {
            this.setState({submitting:false},()=>{
                this.props.onSuccess();
            });
        })
        .catch((error)=> {
            let response = error.response;
            let message = "Unexpected Error";
            if(!(response===null||response===undefined)){
                if(!(response.data.error===null||response.data.error===undefined)){
                    message = response.data.error;
                }
            }
            toast.error(message,{position:"top-center"});
            this.setState({submitting:false});
        });
    };

    render() {
        const errorLabel = <Label color="red" pointing="left"/>;
        let estimate = this.getEstimate();
        let estimateMessage = <span></span>;
        if(this.props.campaignData.complete){
            estimateMessage = <span>Congratulations!<br/>You have completed your campaign!</span>
        } else if(estimate>=0){
            estimateMessage = <span>Your estimated donation based on the remaining miles: {new Intl.NumberFormat('en-US', {
                style: 'currency',
                currency: 'USD'
            }).format(estimate)}</span>
        }else{
            estimateMessage = <span>Goal has already been met!</span>
        }
        if(estimate<0 || this.props.campaignData.complete){
            return(
                <div className={"pledge-form"}>
                    <ToastContainer />
                    <div className={"pledge-title"}>
                        {estimateMessage}
                    </div>
                </div>
            );
        }
        let leftMiles = this.props.campaignData.milesGoal - this.props.campaignData.currentMiles;
        return (
            <div className={"pledge-form"}>
                <ToastContainer />
                <Form loading={this.state.submitting} onValid={()=>this.setState({validForm:true})} onInvalid={()=>this.setState({validForm:false})}>
                    <div className={"pledge-title"}>
                        I Pledge...
                    </div>
                    <div className={"pledge-amount-per-mile"}>
                        <Form.Input type={"number"} required validations={{
                            isInt: true,
                            lessThanMaxAmount: function (values, value) {
                                let cents = value/100;
                                let maxAmount = Math.floor((500/leftMiles)*100);
                                return cents <= maxAmount;
                            }
                        }} validationErrors={{
                            isInt: 'Integer Required',
                            lessThanMaxAmount: 'exceeds $500 pledge limit',
                            isDefaultRequiredValue: 'This is Required',
                        }} errorLabel={ errorLabel } name="pledge-amount" onChange={(e,data)=>this.setState({amountPerMile:data.value})} className={"amount"}/>
                        <div className={"pledge-cents"}>
                            ¢
                        </div>
                        <div className={"pledge-per-mile-message"}>
                            per mile
                        </div>
                    </div>
                    <div className={"pledge-estimate"}>
                        {estimateMessage}
                    </div>
                    <div className={"center-h"}>
                        <div className={"pledge-inputs"}>
                            <div className={"pledge-full-name"}>
                                <Form.Input name="pledge-fullname" required validationErrors={{
                                    isDefaultRequiredValue: 'This is Required',
                                }} errorLabel={ errorLabel } placeholder={"Your Full Name"} className={"pledge-text-input"} onChange={(e,data)=>this.setState({fullName:data.value})}/>
                            </div>
                            <div className={"pledge-email"}>
                                <Form.Input name="pledge-email" required validations="isEmail" validationErrors={{
                                    isEmail: 'This is not a valid email',
                                    isDefaultRequiredValue: 'This is Required',
                                }} errorLabel={ errorLabel } placeholder={"Your Email Address"} className={"pledge-text-input"} onChange={(e,data)=>this.setState({email:data.value})}/>
                            </div>
                            <div className={"pledge-do-not-show"}>
                                <Form.Checkbox name="pledge-do-not-show" className={"pledge-checkbox"} onChange={(e,data)=>this.setState({doNotShow:data.checked})} label='Do not show my name or pledge amount in the donors list'/>
                            </div>
                            <div className={"pledge-receive-emails"}>
                                <Form.Checkbox defaultChecked name="pledge-receive-emails" className={"pledge-checkbox"} onChange={(e,data)=>this.setState({receiveEmails:data.checked})} label={'I would like to receive monthly updates on '+this.props.campaignData.user.fName+'’s progress'}/>
                            </div>
                        </div>
                    </div>
                    <div className={"center-h"}>
                        <button disabled={!this.state.validForm} className={"ui button pledge-button"} onClick={this.onSubmit}>Pledge</button>
                    </div>
                </Form>
            </div>
        );
    }
}

export default PledgeForm;

import React from 'react';
import './DonorList.css';
import {Grid,Table} from 'semantic-ui-react'

class DonorList extends React.Component{

    getDonorRow = (pledgeData,index) =>{
        if(pledgeData.hideOnDonorList){
            return null;
        }
        return(
            <Table.Row key={"pledge-"+index} className={"donor-row"}>
                <Table.Cell>{pledgeData.name}</Table.Cell>
                <Table.Cell className={"amount-cell"}>
                    {(pledgeData.moneyAmount*100).toFixed(0)}¢/mile
                </Table.Cell>
            </Table.Row>
        );
    };

    getDonorTable = () =>{
        return(
            <div className={"donor-table"}>
                <Table fixed unstackable>
                    <Table.Body>
                        {this.props.pledges.map((pledgeData, index)=>{
                            return this.getDonorRow(pledgeData,index);
                        })}
                    </Table.Body>
                </Table>
            </div>
        );
    };

    render() {
        return (
            <div className={"donor-list"+(this.props.pledges.length===0?" empty":"")}>
                <Grid verticalAlign="middle">
                    <Grid.Column computer={5} mobile={16}>
                        <div className={"combined-pledges-wrapper"}>
                            <div className={"combined-pledges"}>
                                <div className={"circle"}/>
                                <div className={ "text"}>
                                    {new Intl.NumberFormat('en-US', {
                                        style: 'currency',
                                        currency: 'USD'
                                    }).format(this.props.combinedPledgeAmount)} <span className={"text-sm"}>per mile</span>
                                </div>
                                <div className={"combined-message"}>
                                    Combined donor pledges
                                </div>
                            </div>
                        </div>
                    </Grid.Column>
                    <Grid.Column computer={6} mobile={16} className={"donor-table-column"}>
                        <div className="list-content-wrapper">
                            <div>
                                <div className={"thankyou-title"}>
                                    {this.props.pledges.length>0||this.props.campaignData.complete?"Thank You Donors!":"Be The First To Pledge!"}
                                </div>
                                {this.getDonorTable()}
                                <div className={"center-h"}>
                                    {this.props.campaignData.complete?null:<button className={"ui button pledge-button"} onClick={this.props.scrollToPledgeForm}>Pledge</button>}
                                </div>
                            </div>
                        </div>
                    </Grid.Column>
                    <Grid.Column computer={5} mobile={16}>
                        <div className={"estimated-total-wrapper"}>
                            <div className={"estimated-total"}>
                                <div className={"circle"}/>
                                <div className={ "text"}>
                                    {new Intl.NumberFormat('en-US', {
                                        style: 'currency',
                                        currency: 'USD'
                                    }).format(this.props.estimatedTotal)}
                                </div>
                                <div className={"estimated-total-message"}>
                                    Total raised to date
                                </div>
                            </div>
                        </div>
                    </Grid.Column>
                </Grid>
            </div>
        );
    }
}

export default DonorList;

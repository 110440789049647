import React from 'react';
import './ConfirmPledge.css';
import Navbar from "../navigation/Navbar";
import Footer from "../navigation/Footer";
import cmAPI from "../axiosInstance";

class ConfirmPledge extends React.Component {

    state = {
        message: "Confirming pledge..."
    };

    componentDidMount() {
        window.scrollTo(0, 0);
        let pledgeUnid = this.props.match.params.pledgeUnid;
        cmAPI.post("/pledging/pledges/"+pledgeUnid+"/confirm")
        .then((response)=> {
            this.setState({message:"Your pledge has been confirmed!"});
            window.location.href = 'http://www.charitymiles.org/pledgethanks';
        })
        .catch((error)=> {
            this.props.history.push('/');
        });
    }

    render() {
        return (
            <>
                <Navbar/>
                <div className="confirm-pledge">
                    <div className={"message"}>
                        {this.state.message}
                    </div>
                </div>
                <Footer/>
            </>
        );
    }
}

export default ConfirmPledge;
